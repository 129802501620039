import { useState } from 'react';
import styled from 'styled-components';

import CancelRecordModal from 'components/modals/CancelRecordModal';
import ConfirmPatientRemovalModal from 'components/modals/ConfirmPatientRemovalModal';
import DischargeModal from 'components/modals/DischargeModal';
import Button from 'components/shared/Button';
import Menu from 'components/shared/menu';
import { useProfile } from 'context/profile';
import Episode from 'models/Episode';
import LocationEpisode from 'models/LocationEpisode';
import { PERMISSIONS } from 'models/Profile';
import colors from 'styles/theme/colors';
import CircleXIconOutlined from 'svg/CircleXIconOutlined';
import EllipsisIcon from 'svg/EllipsisIcon';
import PencilIcon from 'svg/PencilIcon';
import TrashIcon from 'svg/TrashIcon';

type Props = {
  episode: Episode;
  invalidateData: () => void;
};

export default function PatientActionsMenu({ episode, invalidateData }: Props) {
  const { profile } = useProfile();
  const [showEditDischargeModal, setShowEditDischargeModal] = useState(false);
  const [showCancelRecord, setShowCancelRecord] = useState(false);
  const [showRemovePatientModal, setShowRemovePatientModal] = useState(false);

  const latestLocationEpisode = episode.latestLocationEpisode;

  const locationEpisodeForEditDischarge = new LocationEpisode({
    id: episode.latestLocationEpisodeDischarge?.locationEpisodeId,
  });

  // We only allow cancelation of the LATEST location episode
  // as long as it is not the only one and it is not archived or discharged
  const locationEpisodeEligibleForCancelation =
    episode.locationEpisodes.length > 1 && !latestLocationEpisode?.archived && !latestLocationEpisode?.discharged;

  const canEditDischargeInformation =
    profile.has(PERMISSIONS.rehabStateDischargeEdit) && episode.latestLocationEpisodeDischarge;
  const canCancelRecord =
    locationEpisodeEligibleForCancelation &&
    profile.has(PERMISSIONS.locationEpisodeDelete, { locationEpisode: latestLocationEpisode, episode });
  const canRemovePatient = profile.has(PERMISSIONS.patientDelete, { episode });

  const showActionsMenu = canEditDischargeInformation || canCancelRecord || canRemovePatient;

  return (
    <>
      {showActionsMenu && (
        <Menu $size='large'>
          <Menu.Trigger>
            <Button
              variant='outline-gray'
              style={{ width: 44, padding: 0, height: 44, flexShrink: 0 }}
              data-cy='patientActionsMenuButton'>
              <EllipsisIcon />
            </Button>
          </Menu.Trigger>
          <Menu.Content position='right' style={{ marginTop: 4 }}>
            {canEditDischargeInformation && (
              <StyledMenuItem onClick={() => setShowEditDischargeModal(true)}>
                <PencilIcon color={'transparent'} stroke={colors.black} strokeWidth={1.5} />
                Edit discharge information
              </StyledMenuItem>
            )}

            {canCancelRecord && (
              <StyledMenuItem onClick={() => setShowCancelRecord(true)}>
                <Menu.Icon>
                  <CircleXIconOutlined width={14} height={14} />
                </Menu.Icon>
                Cancel record at {latestLocationEpisode?.rehabInformation.latestRehabFacility.name}
              </StyledMenuItem>
            )}

            {(canEditDischargeInformation || canCancelRecord) && canRemovePatient && <Menu.Divider />}

            {canRemovePatient && (
              <StyledMenuItem style={{ color: colors.accentRed }} onClick={() => setShowRemovePatientModal(true)}>
                <Menu.Icon>
                  <TrashIcon width={14} height={14} />
                </Menu.Icon>
                Remove patient from Olio
              </StyledMenuItem>
            )}
          </Menu.Content>
        </Menu>
      )}

      {showEditDischargeModal && (
        <DischargeModal
          episode={episode}
          locationEpisode={locationEpisodeForEditDischarge}
          setShow={setShowEditDischargeModal}
          patientName={episode.patient.name}
          invalidateData={invalidateData}
        />
      )}

      {showCancelRecord && latestLocationEpisode && (
        <CancelRecordModal
          locationEpisodeId={latestLocationEpisode.id}
          patientName={episode.patient.name}
          groupName={latestLocationEpisode.rehabInformation.latestRehabFacility?.name || ''}
          onCancel={() => setShowCancelRecord(false)}
          onSuccess={() => setShowCancelRecord(false)}
        />
      )}

      {showRemovePatientModal && (
        <ConfirmPatientRemovalModal
          episodeId={episode.id}
          patientName={episode.patient.name}
          onCancel={() => setShowRemovePatientModal(false)}
        />
      )}
    </>
  );
}

const StyledMenuItem = styled(Menu.Item)`
  gap: 12px;
`;
